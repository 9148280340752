<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Carga Registro Fotográfico </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
           <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loading"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loading"
                v-model="archivoLayoutId"
                :items="items"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Archivo del Layout Registro Fotográfico"
                :rules="required"
              ></v-select>
            </v-col>
           </v-row>  
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                ref="archivo"
                v-model="archivo"
                label="Cargar archivo zip"
                prepend-icon="mdi-paperclip"
                show-size
                accept=".zip"
                :rules="required"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="secondary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="cargar()"
          :disabled="!valid"
          >Cargar Archivo</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import registroFotograficoService from "@/services/registroFotografico.service";
import ArchivosService from "@/services/archivo.service";
import validationField from "@/util/validationField";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      items: [],
      archivoLayoutId: undefined,
      archivo: undefined,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Repositorio Fotográfico - Cargar"))
      this.$router.push("/dashboard");

    this.fetchItems("?activo=1");
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      ArchivosService.getArchivosRepositorioFotografico().then(
        (response) => {
          this.items = response.data.map(function f(x){
            return { id:x.id, nombre: x.id+'. '+x.nombre };
          });
          this.items=this.items.reverse();

          /*this.items = this.items.map(function f(x) {
            return { ...x, layout_nombre: x.layout.nombre };
          });*/
          //console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    cargar() {
      this.loadingSave = true;
      
      let formFile = new FormData()
      
      formFile.append('archivoLayoutId', this.archivoLayoutId)
      formFile.append('archivo', this.archivo)

      registroFotograficoService.uploadFile( formFile ).then(
      (response) => {
          this.$router.push({ path: `/repositorio-fotografico/cargas` });
          console.log(response.data);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );  
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/repositorio-fotografico/cargas" });
    },
  },
};
</script>

<style>
</style>
